import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from 'components/ui/select'
import { addDays, format } from 'date-fns'

import { Calendar } from 'components/ui/calendar'
import { CalendarDays } from 'lucide-react'
import clsx from 'clsx'

interface Props {
	onSelect: (value?: Date) => void
	selectedDate?: Date
	content?: React.ReactNode
	showPresets?: boolean
	label?: string
	className?: string
	isReadOnly?: boolean
	fromDate?: Date
}

export default function SpokDatePicker({
	// eslint-disable-next-line react/no-object-type-as-default-prop
	selectedDate = new Date(),
	onSelect,
	content,
	showPresets = true,
	label,
	className,
	isReadOnly,
	fromDate
}: Props) {
	if (isReadOnly)
		return (
			<div>
				{content ? (
					<div className='group flex items-center gap-2'>{content}</div>
				) : (
					<div className='group flex w-fit items-center gap-1'>
						{format(selectedDate, 'yyyy-MM-dd')}
					</div>
				)}
			</div>
		)

	return (
		<Popover>
			<PopoverTrigger asChild>
				<div className='cursor-pointer'>
					{content ? (
						<div>{content}</div>
					) : (
						<div className='flex flex-col'>
							{label && (
								<div className='mb-2 block text-xs font-bold text-gray-700'>
									{label}
								</div>
							)}
							<div className={clsx('flex w-fit items-center gap-1', className)}>
								<div className='flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-gray-200'>
									<CalendarDays
										color='black'
										className='text-white'
										size={15}
									/>
								</div>
								<div className='text-sm'>
									{format(new Date(selectedDate), 'yyyy-MM-dd')}
								</div>
							</div>
						</div>
					)}
				</div>
			</PopoverTrigger>
			<PopoverContent
				align='start'
				className='z-[999999] flex w-auto flex-col space-y-2 p-2'
			>
				{showPresets && (
					<Select
						value={selectedDate.toISOString()}
						onValueChange={(value: string) => {
							if (value === '0') {
								onSelect(new Date())
							}
							if (value === '1') {
								onSelect(addDays(new Date(), 1))
							}
							if (value === '3') {
								onSelect(addDays(new Date(), 3))
							}
							if (value === '7') {
								onSelect(addDays(new Date(), 7))
							}
						}}
					>
						<SelectTrigger>
							<SelectValue placeholder='Presets' />
						</SelectTrigger>
						<SelectContent position='popper' className='z-[999999]'>
							<SelectItem value='0'>Today</SelectItem>
							<SelectItem value='1'>Tomorrow</SelectItem>
							<SelectItem value='3'>In 3 days</SelectItem>
							<SelectItem value='7'>In a week</SelectItem>
						</SelectContent>
					</Select>
				)}
				<Calendar
					mode='single'
					defaultMonth={selectedDate}
					selected={selectedDate}
					onSelect={date => {
						onSelect(date)
					}}
					fromDate={fromDate ?? new Date()}
				/>
			</PopoverContent>
		</Popover>
	)
}
